<script setup>
import { useMainStore } from '@/store';
import anims from "@/utils/animFunctions";

defineProps({
  width: {
    type: String,
    required: true,
  },
  height: {
    type: String,
    required: true,
  },
  stroke: {
    type: String,
    required: true,
  },
  color: {
    type: String,
    required: true,
  },
});

const store = useMainStore();

const isMobile = computed(() => store.isMobile);

</script>

<template>
  <svg
    class="playButton"
    :width="width"
    :height="height"
    :stroke-width="stroke"
    :stroke="color"
    data-anim="videoPlayButton"
    :data-anim-svg-width="width"
    :data-anim-svg-height="height"
    :data-anim-svg-stroke="stroke"
  >
    <line
      v-show="!isMobile"
      :x1="stroke"
      :y1="stroke"
      :x2="stroke"
      :y2="stroke"
    />
    <line
      v-show="!isMobile"
      :x1="stroke"
      :y1="height - stroke"
      :x2="stroke"
      :y2="height - stroke"
    />
    <line
      v-show="!isMobile"
      :x1="stroke"
      :y1="stroke"
      :x2="stroke"
      :y2="stroke"
    />
    <line
      v-show="isMobile"
      :x1="stroke"
      :y1="stroke"
      :x2="width - stroke"
      :y2="height / 2"
    />
    <line
      v-show="isMobile"
      :x1="width - stroke"
      :y1="height / 2"
      :x2="stroke"
      :y2="height - stroke"
    />
    <line
      v-show="isMobile"
      :x1="stroke"
      :y1="height - stroke"
      :x2="stroke"
      :y2="stroke"
    />
  </svg>
</template>

<style lang="scss" scoped>
.playButton {
  fill: transparent;
}
</style>
